import { Route, Routes } from 'react-router-dom'
import Navbar from './components/navbar'
import Home from './Home'
import InfosPratiques from './pages/InfoPratiques'
import Photos from './pages/Photos'
import Where from './pages/Where'

function App() {
  return (
    <div className="flex flex-col">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />{' '}
        {/* Page d'accueil, remplace par ton composant Home */}
        <Route path="/infos" element={<InfosPratiques />} />
        {/* Ajoute d'autres routes ici */}
        <Route path="/photos" element={<Photos />} />
        <Route path="/where" element={<Where />} />
        <Route path="*" element={<div>404 - Page non trouvée</div>} />
      </Routes>
    </div>
  )
}

export default App

import React from 'react'

const Home: React.FC = () => {
  return (
    <div className="flex-1 relative bg-[#fcf6ed] h-screen overflow-hidden">
      <div className="container mx-auto px-4 h-full fixed inset-0">
        {/* Layout principal avec grid pour desktop */}
        <div className="grid md:grid-cols-2 h-full items-center relative">
          {/* Colonne de gauche pour l'image */}
          <div className="hidden md:block h-full flex  justify-self-center">
            <img
              src="/images/bouquet.png"
              alt="Flower decoration"
              className="w-[400px] pointer-events-none"
            />
          </div>

          {/* Colonne de droite pour le texte */}
          <div className="flex flex-col items-center md:items-start justify-center md:justify-start mt-6 md:mt-[-15%]">
            <div className="text-center max-w-lg">
              <div className="font-playfair-sc text-[rgb(139,111,82)] text-4xl md:text-7xl mb-4 tracking-wide flex flex-col items-center gap-4">
                <span>MELISSA</span>
                <span className="text-2xl md:text-2xl font-mono">&</span>
                <span className="-m-2">CHARLES</span>
              </div>

              <div className="mt-10 text-[#8B6F52] -space-y-2">
                <p className="font-playfair text-xl">SAMEDI 14 JUIN</p>
                <p className="font-playfair text-lg">2025</p>
              </div>
            </div>

            {/* Image mobile uniquement */}
            <div className="md:hidden w-full mt-12">
              <img
                src="/images/bouquet.png"
                alt="Flower decoration"
                className="w-full max-w-[200px] mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home

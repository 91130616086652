import React from 'react'

const Where: React.FC = () => {
  return (
    <div className="min-h-screen bg-[#fcf6ed] py-12 font-playfair">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl md:text-4xl font-playfair text-custom-color mb-8 text-center">
            Les lieux
          </h1>

          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <h2 className="text-xl font-playfair text-custom-color mb-4">La Mairie</h2>
            <p className="text-gray-700 mb-4">
              La cérémonie civile aura lieu à la mairie de Bazoches-sur-Hoëne à 14h30.
            </p>
            <div className="aspect-w-16 aspect-h-10 mb-4 h-[300px] md:h-[400px]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2633.9893811562824!2d0.46793567670893166!3d48.55286366767761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e23ad6a7acfd49%3A0x6cc47e8bf4e8669b!2sMairie!5e0!3m2!1sfr!2sfr!4v1709832799772!5m2!1sfr!2sfr"
                height="100%"
                className="w-full h-full rounded-lg"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <h2 className="text-xl font-playfair text-custom-color mb-4">
              La Bergerie de Milan
            </h2>
            <p className="text-gray-700 mb-4">
              La Bergerie de Milan est situé à l'extérieur de Bazoches-sur-Hoëne. Suivez
              les instructions sur Maps. Une fois arrivé à la Bergerie de Milan, vous
              pourrez vous garer à l'entrée de la propriété pour ensuite nous rejoindre au
              Jardin de Milan. Il est situé en haut d'une petite colline. Prévoyez aussi
              des chaussures plates confortables pour la montée.
            </p>
            <div className="aspect-w-16 aspect-h-10 mb-4 h-[300px] md:h-[400px]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5279.555518569323!2d0.45777827670898445!3d48.53169899999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e23aef211e9029%3A0xa0a6284bf53566bb!2sMilan%2C%2061560%20Bazoches-sur-Ho%C3%ABne!5e0!3m2!1sfr!2sfr!4v1709831537811!5m2!1sfr!2sfr&markers=color:red%7C48.53169899999999,0.4577782767089844"
                height="100%"
                className="w-full h-full rounded-lg"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <h2 className="text-xl font-playfair text-custom-color mb-4">
              Comment venir ?
            </h2>
            <div className="space-y-4">
              <div>
                <h3 className="font-semibold text-custom-color mb-2">En train</h3>
                <p className="text-gray-700">
                  La gare la plus proche est celle de L'Aigle. Des trains directs partent
                  régulièrement de Paris Montparnasse (1h45 de trajet). Puis comptez 35
                  minutes en voiture pour arriver à Milan.
                </p>
              </div>
              <div>
                <h3 className="font-semibold text-custom-color mb-2">En voiture</h3>
                <p className="text-gray-700">
                  Depuis Paris, comptez environ 2h de route.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-playfair text-custom-color mb-4">Où dormir ?</h2>
            <p className="text-gray-700 mb-6">
              Voici une sélection d'hébergements suggérés à proximité :
            </p>

            {/* Hotels */}
            <div className="mb-8">
              <h3 className="font-semibold text-custom-color text-lg mb-3">Hôtels</h3>
              <ul className="space-y-2 text-gray-700">
                <li>
                  <a
                    href="https://www.parc-naturel-perche.fr/ou-dormir-0/chambres-d-hotes/chambre-d-hotes-hotel-des-tailles-768655"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    Hotel des Tailles
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.chateaudeblavou.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    Château de Blavou
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.hotel-tribunal.fr/en/home/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    Hotel du Tribunal
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.maisonceronne.com/accueil.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    Maison Ceronne ⭐
                  </a>
                </li>
              </ul>
            </div>

            {/* Chambres d'hôtes et Gîtes */}
            <div className="mb-8">
              <h3 className="font-semibold text-custom-color text-lg mb-3">
                Chambres d'hôtes et Gîtes
              </h3>
              <ul className="space-y-2 text-gray-700">
                <li>
                  <a
                    href="https://www.booking.com/hotel/fr/les-chambres-perchees-de-boece.fr.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    Les chambres perchées de Boëce
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.booking.com/hotel/fr/le-vallon-du-perche.fr.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    Le Vallon du Perche
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.gites-de-france-orne.com/fiche-hebergement-G897.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    La Barie
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.fermedelasimonniere.com/la-chambre-d-hôtes/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    La Simonnière
                  </a>
                </li>
                <li>
                  <a
                    href="https://rando-perche.fr/service/172857-Gite-La-Petite-Maison-Landon"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    La Petite Maison Landon
                  </a>
                </li>
              </ul>
            </div>

            {/* Maisons et Appartements */}
            <div>
              <h3 className="font-semibold text-custom-color text-lg mb-3">
                Maisons et Appartements
              </h3>
              <ul className="space-y-2 text-gray-700">
                <li>
                  <a
                    href="https://www.booking.com/Share-ZIrNDP"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    La Parenthèse
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.booking.com/Share-P7GX6L"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    Appartement Le Souffle de l'Orge
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.booking.com/Share-vZuwfP"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    Gîte La Mesnière (11 personnes)
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.couplehaut.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-custom-color transition-colors"
                  >
                    Château de Couplehaut (12 personnes)
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Where

import React from 'react'

const InfosPratiques = () => {
  return (
    <div className="min-h-screen bg-[#fcf6ed] py-12 font-playfair">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h1 className="text-3xl md:text-4xl font-playfair text-custom-color mb-6">
            Programme de la journée
          </h1>

          <p className="text-gray-700 mb-12 max-w-xl mx-auto">
            Nous sommes ravis de vous convier à notre célébration de mariage.
          </p>

          <div className="flex flex-col md:flex-row justify-center items-center gap-16 md:gap-32 relative">
            {/* Mairie */}
            <div className="text-center">
              <h2 className="text-xl text-custom-color mb-2">Mairie</h2>
              <p className="text-lg text-gray-700">14h30</p>
            </div>

            {/* Image fleur centrale */}
            <div className="relative -my-4">
              <img
                src="/images/petite_fleur.png"
                alt="Décoration florale"
                className="w-24 md:w-32 opacity-80"
              />
            </div>
            {/* Jardin de Milan */}
            <div className="text-center">
              <h2 className="text-xl text-custom-color mb-2">Jardin de Milan</h2>
              <p className="text-lg text-gray-700">16h30</p>
            </div>
          </div>

          {/* Timeline détaillée */}
          <div className="mt-20 max-w-2xl mx-auto">
            <div className="bg-white rounded-lg shadow-md p-8">
              <h3 className="text-xl font-semibold mb-6 text-custom-color">
                Déroulé de la journée
              </h3>

              <div className="space-y-4">
                <div className="flex items-start gap-6">
                  <span className="text-custom-color font-semibold w-20">14h30</span>
                  <p className="text-gray-700">
                    Cérémonie civile à la{' '}
                    <a
                      href="https://maps.app.goo.gl/xzaYMR4ELJcknjfU8"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-custom-color hover:underline"
                    >
                      Mairie de Bazoches-sur-Hoëne
                    </a>
                  </p>
                </div>

                <div className="flex items-start gap-6">
                  <span className="text-custom-color font-semibold w-20">16h30</span>
                  <p className="text-gray-700">
                    Cocktail au jardin de la{' '}
                    <a
                      href="https://maps.app.goo.gl/xpyMe4eefZdJxafz5"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-custom-color hover:underline"
                    >
                      Bergerie de Milan
                    </a>
                  </p>
                </div>

                <div className="flex items-start gap-6">
                  <span className="text-custom-color font-semibold w-20">19h30</span>
                  <p className="text-gray-700">Dîner</p>
                </div>

                <div className="flex items-start gap-6">
                  <span className="text-custom-color font-semibold w-20">22h30</span>
                  <p className="text-gray-700">Soirée dansante 💃🕺</p>
                </div>
              </div>

              <div className="mt-8 pt-6 border-t border-gray-200">
                <h3 className="text-xl font-semibold mb-4 text-custom-color">
                  Le lendemain
                </h3>
                <div className="flex items-start gap-6">
                  <span className="text-custom-color font-semibold w-20">13h00</span>
                  <p className="text-gray-700">Brunch & piscine 🌞 s'il fait beau</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfosPratiques

import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'
import PhotoGallery from './photoGallery'
import { useState } from 'react'

const Photos: React.FC = () => {
  const [photosUpdated, setPhotosUpdated] = useState(false) // État pour déclencher le rechargement

  // Configurer le client S3
  const s3Client = new S3Client({
    region: 'eu-west-3',
    credentials: {
      accessKeyId: 'AKIA4ETI3TZRUYPAECV3',
      secretAccessKey: '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'
    }
  })

  // Fonction pour gérer le téléchargement
  const handleUpload = async (files: FileList | null) => {
    if (!files) return

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const command = new PutObjectCommand({
        Bucket: 'mariagemetc',
        Key: file.name,
        Body: file,
        ContentType: file.type
      })

      try {
        await s3Client.send(command)
        console.log(`${file.name} uploaded successfully.`)
        setPhotosUpdated(prev => !prev) // Inverse l'état pour déclencher le rechargement
      } catch (error) {
        console.error(`Error uploading ${file.name}:`, error)
      }
    }
  }
  return (
    <div className="p-4 font-playfair">
      <h2 className="text-xl font-semibold text-custom-color mb-4">
        Partagez vos photos !
      </h2>
      <p className="text-gray-700">
        Sur cette page vous pourrez partager toutes les photos prises le Jour J. Ces
        souvenirs pourront être consultés par tout le monde en temps réel. Nous comptons
        sur vous pour partager un maximum de photos !
      </p>
      <p className="text-gray-700 mb-6">
        ⚠️ Attention, une photo chargée ne pourra pas être supprimée 😇⚠️
      </p>

      {/* Section pour télécharger des photos */}
      {/* <div className="mb-8">
        <h3 className="text-lg font-semibold text-custom-color">
          Télécharger des photos
        </h3>
        <input
          type="file"
          accept="image/*"
          multiple
          className="border p-2"
          onChange={e => handleUpload(e.target.files)}
        />
      </div> */}

      {/* Section pour afficher les photos téléchargées */}
      {/* <PhotoGallery photosUpdated={photosUpdated} /> */}
    </div>
  )
}

export default Photos
